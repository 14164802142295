import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';  // Import the CSS file for styling
import { API_BASE_URL } from '../config/config';


console.log("current setting for API BASE URL:")
console.log(`${API_BASE_URL}`)

const Login = ({ setToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`${API_BASE_URL}/api/token/`, {
            username, password
        })
        .then(response => {
            setToken(response.data.access);
            navigate('/dashboard');  // Redirect to the dashboard
        })
        .catch(error => {
            console.error("Login failed:", error);
            setError('Incorrect username or password');
        });
    };

    return (
        <div className="login-container">
                {/* Background Video */}
            <video className="login-background" autoPlay muted loop>
                <source src={require('../datas/secretary.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="login-box">
                <h1>Login to Obelisk</h1>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                    <button type="submit">Login</button>
                </form>
            </div>
        </div>
    );
};

export default Login;
