import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AssistantConfig from './pages/AssistantConfig';
import Conversations from './pages/Conversations';
import PrivateRoute from './components/PrivateRoute';  // Import PrivateRoute
import Header from './components/Header';  // Import Header

function App() {
    const [token, setToken] = useState(localStorage.getItem('token'));

    // Update localStorage when the token changes
    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');  // Clear token if it's null
        }
    }, [token]);

    // Handle logout function
    const handleLogout = () => {
        setToken(null); // Clear token from state
    };

    return (
        <Router>
            {/* Only show the Header if the user is logged in */}
            {token && <Header token={token} onLogout={handleLogout} />}

            <Routes>
                {/* Public route: Login */}
                <Route path="/login" element={<Login setToken={setToken} />} />

                {/* Protected routes */}
                <Route path="/dashboard" element={
                    <PrivateRoute token={token}>
                        <Dashboard />
                    </PrivateRoute>
                } />

                <Route path="/assistant-config" element={
                    <PrivateRoute token={token}>
                        <AssistantConfig />
                    </PrivateRoute>
                } />

                <Route path="/conversations" element={
                    <PrivateRoute token={token}>
                        <Conversations />
                    </PrivateRoute>
                } />

                {/* Default route: Redirect to dashboard if logged in, otherwise login */}
                <Route path="/" element={
                    token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
                } />
            </Routes>
        </Router>
    );
}

export default App;
