import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import './AssistantConfig.css';  // Import CSS for styling
import { API_BASE_URL } from '../config/config';

const AssistantConfig = () => {
    const [configs, setConfigs] = useState([]);
    const [newConfig, setNewConfig] = useState({
        category: '',
        prompt: '',
        response: ''
    });
    const [editMode, setEditMode] = useState(false);
    const [currentConfigId, setCurrentConfigId] = useState(null);
    const [loading, setLoading] = useState(false);  // State to manage loading circle
    const [taskStatus, setTaskStatus] = useState(null);  // State to store task status

    // Fetch existing configurations on component mount
    useEffect(() => {
        axios.get(`${API_BASE_URL}/api/assistant-config/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(response => {
            setConfigs(response.data);
        }).catch(error => {
            console.error("Error fetching configurations:", error);
        });
    }, []);

    // Handle submitting the form for creating or updating a config
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare data to be sent to the backend
        const configData = {
            category: newConfig.category,
            prompt: newConfig.prompt,
            response: newConfig.response
        };

        setLoading(true);  // Show loading spinner

        try {
            let response;
            if (editMode) {
                // Update existing configuration
                response = await axios.put(`${API_BASE_URL}/api/assistant-config/${currentConfigId}/`, configData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setConfigs(configs.map(config => config.id === currentConfigId ? response.data : config));
            } else {
                // Add new configuration
                response = await axios.post(`${API_BASE_URL}/api/assistant-config/`, configData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setConfigs([...configs, response.data]); // Add the new config to the list
            }

            // Extract task_id from the response and initiate task polling
            const task_id = response?.data?.task_id;
            if (task_id) {
                setTaskStatus('in_progress');
                pollTaskStatus(task_id);
            } else {
                console.error("task_id is undefined, check backend response.");
            }

            // Reset form and UI states
            setEditMode(false);
            setNewConfig({ category: '', prompt: '', response: '' });
            setCurrentConfigId(null);

        } catch (error) {
            console.error("Error updating or adding configuration:", error);
        } finally {
            setLoading(false);  // Hide loading spinner
        }
    };

    // Function to poll the task status using task_id
    const pollTaskStatus = async (task_id) => {
        try {
            const interval = setInterval(async () => {
                const res = await axios.get(`${API_BASE_URL}/api/task-status/${task_id}`);
                if (res.data.status === 'SUCCESS') {
                    setTaskStatus('completed');
                    clearInterval(interval);
                    toast.success(`Audio from has been successfully generated!`)
                } else if (res.data.status === 'failed') {
                    setTaskStatus('failed');
                    clearInterval(interval);
                    toast.error(`Failed to generate audio. Please try again.`);
                }
            }, 3000);  // Poll every 3 seconds
        } catch (error) {
            console.error('Error polling task status:', error);
        }
    };

    // Handle editing a configuration
    const handleEdit = (config) => {
        setNewConfig({ category: config.category, prompt: config.prompt, response: config.response });
        setEditMode(true);
        setCurrentConfigId(config.id);
    };

    // Cancel the edit mode
    const handleCancelEdit = () => {
        setEditMode(false);
        setNewConfig({ category: '', prompt: '', response: '' });
        setCurrentConfigId(null);
    };

    // Handle deleting a configuration
    const handleDelete = (id) => {
        axios.delete(`${API_BASE_URL}/api/assistant-config/${id}/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(() => {
            setConfigs(configs.filter(config => config.id !== id));  // Remove the deleted config from the list
        }).catch(error => {
            console.error("Error deleting configuration:", error);
        });
    };

    return (
        <div className="config-container">
            <h1>Configuration</h1>
            <form onSubmit={handleSubmit} className="config-form">
                <input
                    type="text"
                    value={newConfig.category}
                    onChange={e => setNewConfig({ ...newConfig, category: e.target.value })}
                    placeholder="Category"
                    className="category-input"
                />
                <textarea
                    type="text"
                    value={newConfig.prompt}
                    onChange={e => setNewConfig({ ...newConfig, prompt: e.target.value })}
                    placeholder="Prompt"
                    className="prompt-input"
                />
                <textarea
                    value={newConfig.response}
                    onChange={e => setNewConfig({ ...newConfig, response: e.target.value })}
                    placeholder="Response"
                    className="response-input"
                />

                {editMode ? (
                    <div>
                        <button type="submit" className="validate-config-btn">Validate</button>
                        <button type="button" className="cancel-edit-btn" onClick={handleCancelEdit}>Cancel</button>
                    </div>
                ) : (
                    <button type="submit" className="add-config-btn">Add Configuration</button>
                )}
            </form>

            {loading && <div className="loading-circle"></div>}  {/* Display loading spinner when task is in progress */}
            {taskStatus === 'completed' && <p>Audio generation completed successfully!</p>}
            {taskStatus === 'failed' && <p>Audio generation failed. Please try again.</p>}

            <h2>Existing Configurations</h2>
            <div className="existing-configs">
                {configs.map(config => (
                    <div key={config.id} className="config-card">
                        <h3 className="config-category">{config.category}</h3>
                        <p><strong>Prompt:</strong> {config.prompt}</p>
                        <p><strong>Response:</strong> {config.response}</p>
                        <button onClick={() => handleEdit(config)} className="edit-btn">Edit</button>
                        <button onClick={() => handleDelete(config.id)} className="delete-btn">Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssistantConfig;
