import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
            <p> Page en cours de création</p>
            <p>Pour tester obelisk, appelez le +1 567 212 2473 (attention numéro étranger, coûts potentiels)</p>
            <p> Configurez l'assistant dans <Link to="/assistant-config">Configurations</Link> </p>
            <p> Retrouvez vos conversations dans <Link to="/conversations">Conversations</Link> </p>
            <nav>
                <ul>

                </ul>
            </nav>
        </div>
    );
};

export default Dashboard;
