import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Header.css';  // Import the CSS file for styling
import { API_BASE_URL } from '../config/config';


const Header = ({ token, onLogout }) => {
    const [username, setUsername] = useState('');
    const navigate = useNavigate();


      useEffect(() => {
        if (token) {
            axios.get(`${API_BASE_URL}/api/user-info/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(response => {
                setUsername(response.data.username);  // Set the username from response
            }).catch(error => {
                console.error("Error fetching user info:", error);
            });
        }
    }, [token]);


    const handleLogoutClick = () => {
        onLogout();  // Call the logout function passed from App.js
        navigate('/login');  // Redirect to the login page
    };

    return (
        <header className="header">
            <nav className="navbar">
                <ul className="navbar-list">
                    <li><Link to="/dashboard">Dashboard</Link></li>
                    <li><Link to="/assistant-config">Configuration</Link></li>
                    <li><Link to="/conversations">Conversations</Link></li>
                    <li><Link to="/assistance">Assistance</Link></li>
                    <li><Link to="/account">Account</Link></li>
                    {token && (
                   <div className="user-info">
                        <span>{username} </span>  {/* Display username */}
                        <button onClick={handleLogoutClick} className="logout-btn">Logout</button>
                    </div>
                )}
                </ul>

                )}
            </nav>
        </header>
    );
};

export default Header;
