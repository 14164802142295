import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Conversations.css';  // Import CSS
import { API_BASE_URL } from '../config/config';

const Conversations = () => {
    const [conversations, setConversations] = useState([]);
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchDate, setSearchDate] = useState('');

    // Fetch conversations on component mount
    useEffect(() => {
        fetchConversations();
    }, []);

    const fetchConversations = async () => {
        const response = await axios.get(`${API_BASE_URL}/api/conversations/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setConversations(response.data);
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        const response = await axios.get(`${API_BASE_URL}/api/search-conversations/?q=${searchQuery}&date=${searchDate}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setConversations(response.data);
    };

    const handleConversationClick = async (conversationId) => {
        const response = await axios.get(`${API_BASE_URL}/api/conversations/${conversationId}/`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        setSelectedConversation(response.data);
    };

    return (
        <div className="conversation-container">
            <div className="left-panel">
                <h2>Conversations</h2>
                <form onSubmit={handleSearch}>
                    <input
                        type="text"
                        placeholder="Search by user or ID"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <input
                        type="date"
                        value={searchDate}
                        onChange={(e) => setSearchDate(e.target.value)}
                    />
                    <button type="submit">Search</button>
                </form>

                <ul className="conversation-list">
                    {conversations.map((conversation) => (
                        <li key={conversation.conversation_id} onClick={() => handleConversationClick(conversation.conversation_id)}>
                            <div className="conversation-summary">
                                <p><strong>Caller ID:</strong> {conversation.caller_id}</p>
                                <p><strong>Timestamp:</strong> {conversation.timestamp}</p>
                                <p><strong>Summary:</strong> {conversation.summary}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className="right-panel">
                {selectedConversation ? (
                    <div className="conversation-thread">
                        <h3>Conversation Details</h3>
                        <div className="conversation-messages">
                            {selectedConversation.messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`message-bubble ${message.sender === 'assistant' ? 'sent' : 'received'}`}
                                >
                                    <p className="message-content">
                                        <strong>{message.sender}:</strong> {message.message}
                                    </p>
                                    <p className="message-timestamp">{message.timestamp}</p>
                                </div>
                            ))}
                        </div>
                        <button onClick={() => window.location.href = `/full-conversation/${selectedConversation.conversation_id}`}>
                            Full View
                        </button>
                    </div>
                ) : (
                    <p>Select a conversation to view details</p>
                )}
            </div>
        </div>
    );
};

export default Conversations;
