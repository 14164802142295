// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// This component protects routes and ensures the user is logged in
const PrivateRoute = ({ token, children }) => {
    // If the user is not logged in, redirect to login
    if (!token) {
        return <Navigate to="/login" />;
    }

    // Otherwise, render the protected component
    return children;
};

export default PrivateRoute;
